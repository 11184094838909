import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { CCard, CCardFooter } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import userViewAdapter from 'commons/adapters/userViewAdapter'
import { HeaderStateContext } from 'templates/context/header-state'
import useLoggedUser from 'templates/context/session-state/useLoggedUser'
import useProfile from 'templates/context/session-state/useProfile'
import useSession from 'templates/context/session-state/useSession'
import CardBody from 'components/card/CardBody'
import CardHeader from 'components/card/CardHeader'
import Button from 'components/form/button'

const Onboard: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const history = useHistory()
  const { hasExpired } = useSession()
  const profile = useProfile()
  const user = userViewAdapter(useLoggedUser().loggedUser)

  useEffect(() => {
    if (profile.isMember()) {
      const mainRoute = makeRoute({ path: routePaths.MAIN })
      history.push(mainRoute)
    }
  }, [])

  useEffect(() => {
    setTitle('Onboard')
    if (hasExpired()) history.push(makeRoute({ path: routePaths.SIGN_IN }))
  }, [])

  return (
    <CCard className="p-2 p-md-4 ">
      <CardHeader
        className="text-center"
        title={<>Seja bem-vindo a Plataforma Omnitax, <strong>{user.getName()}</strong>!</>}
      />
      <CardBody>
        <div className="mb-5">
          <p className="h5 font-weight-normal mb-4">O registro do seu usuário foi concluído com sucesso.</p>
          <p className="h5 font-weight-normal mb-4">Agora falta associá-lo a uma conta previamente registrada em algum domínio.</p>
          <p className="h5 font-weight-normal mb-4">
            Se você deseja registrar uma conta abaixo do domínio Omnitax, por favor,
            entre em contato com suporte@omnitax.ai e solicite informações adicionais
            sobre como registrar uma conta abaixo do domínio Omnitax.
          </p>
          <p className="h5 font-weight-normal mb-4">
            Se você deseja configurar seu próprio domínio, por favor,
            entre em contato com suporte@omnitax.ai e solicite informações adicionais
            sobre como registrar um domínio próprio.
          </p>
          <p className="h5 font-weight-normal mb-4">
            Obrigado!
          </p>
          <p className="h5 font-weight-normal">
            Equipe de Atendimento ao Cliente.
          </p>
        </div>
        <div className="text-center">
          <Button
            to={routePaths.SIGN_IN}
            color="link"
            size="md"
          >
            Voltar para a página inicial
          </Button>
        </div>

        <CCardFooter />
      </CardBody>
    </CCard>
  )
}

export default Onboard
