import { DocumentFormatted } from './functionsInvoiceAdapter';

const autXMLInvoiceFormAdapter = (
  data: any
): any => {
  const autXMLFormatted = Array.isArray(data) && data?.map((item: any): any => {
    return DocumentFormatted(item)
  })

  return autXMLFormatted || []
}

export default autXMLInvoiceFormAdapter
